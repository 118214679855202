<template>
    <!-- options -->
    <div>
        <div class="">
            <h2>Options</h2>
        </div>
        <div class="marg-big">
            <h2>Thresholds</h2>
            <div>Simplifies adding process by blocking word adding from words beneath certain thresholds.</div>
        </div>
        <div class="row marg-big">
            <label class="col-6 col-md-3 align-right"><h2>Freq</h2></label>
            <input class="col-6 col-md-3 align-left" type="number" v-model.lazy="opts.freq">
            <label class="col-6 col-md-3 align-right"><h2>WK</h2></label>
            <input class="col-6 col-md-3 align-left" type="number" v-model.lazy="opts.wk">
        </div>
        <h2 class="marg-big">Others</h2>
        <div class="row marg-big">
            <label class="col-6 col-md-3 align-right"><h2>Pinned:<br>Sort by Freq</h2></label>
            <select class="col-6 col-md-3 align-left" v-model="opts.pinnedSortByFreq">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Options',
    props: ['opts']
}
</script>

<style lang="sass" scoped>
label
    min-width: 2em
    text-align: right
    padding-right: 2em
input, select
    width: 5em
    display: block
</style>
