<template>
<div class="main">
    <div @click="$emit('generate-list')" class="main-primary clickable badge bg-purple pad-big">
        <h1>
            <i class="fas fa-tools" style="margin-right: .5em"></i>
            <slot name="msg"></slot>
        </h1>
    </div>
    <slot class="main-slot" name="sidebar-modal-toggle"></slot>
</div>
</template>

<script>
export default {
    name: 'Generate',
    props: ['len']
}
</script>

<style lang="sass" scoped>
.main
    display: flex
    .main-primary
        flex-grow: 1
    .main-slot
        flex-grow: 0
        flex-shrink: 1
</style>
