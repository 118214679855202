<template>
    <div>
        <span v-for="(items, indx) in list" :key="indx + 'aaa'" class="badge clickable" :class="active === items ? 'bg-purple' : 'bg-secondary'" @click="$emit('nav-changed', items)">{{capitalize(items)}}</span>
    </div>
</template>

<script>
export default {
    name: 'Navigation',
    props: ['active', 'list'],
    methods: {
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        }
    }
}
</script>
