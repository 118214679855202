<template>
    <div class="book-card pad-big container-fluid" :class="locked && !item.in_user ? 'lock-card' : ''">
        <div class="row">
            <!-- head word -->
            <div class="col-12 col-md-3 marg-small" style="align-self: center;">
                <div class="header-cell align-center">
                    <div>
                        <span class="badge bg-purple" lang="ja">
                            <span v-if="kanjiInfo.length === 0">{{item.word}}</span>
                            <span v-else-if="kanjiInfo.length > 0"
                                v-for="(char, charIndx) in relevantKanji"
                                v-tippy
                                :content="char.kanji"
                                :key="charIndx+'z'"
                            >{{char.char}}</span>
                        </span>
                    </div>
                    <div><span v-if="item.in_wk" class="badge bg-success">wk {{item.in_wk}}</span></div>
                    <div><span v-if="item.in_user" class="badge bg-jet">known</span></div>
                </div>
            </div>
            <!-- entries -->
            <div class="col marg-small">
                <!-- header -->
                <div
                v-for="(entry, indx) in relevantHeaderEntries" :key="indx"
                lang="ja"
                class="badge clickable header-badge"
                :class="item.entries[activeIndex].entry === entry.entry ? 'bg-purple' : 'bg-secondary'" @click="[toggleAsActive(entry.entry), $emit('pin-card', item.id)]"
                >
                    <span>{{entry.reading === item.word || entry.reading === "" ? entry.entry : entry.reading}}</span>
                </div>
                <!-- definitions -->
                <div style="min-width: 15em"  v-for="(entry, indx) in relevantDefinitions" :key="indx+item.word">
                    <!-- pos & pronunciations -->
                    <div class="speech-row">
                        <span>
                            <!-- show pronunciation if possible -->
                            <span role="pronunciation"
                                v-for="(pronun, pIndx) in getPronunciationsForEntry(entry) || [entry.reading]"
                                class="badge bg-purple-2"
                                :key="pIndx+'a'"
                                v-html="pronun.pronunciation"
                            ></span>
                        </span>
                        <span class="badge bg-info" v-for="(pos, posIndx) in entry.pos.split(' ')" :key="posIndx+item.word">{{pos}}</span>
                    </div>
                    <div>
                        <span class="badge bg-secondary"
                            v-for="(defs, indx) in entry.definition.slice(0,8)"
                            :title="defs"
                            :key="indx+'c'"
                        >
                            {{defs.length > 33 ? defs.substring(0,33)+'…' : defs}}
                        </span>
                        <span v-if="entry.definition.length > 3" class="badge bg-secondary" v-tippy :content="entry.definition.join(', ')">
                            <i class="fa fa-ellipsis-h"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2 marg-small">
                <span v-if="!item.in_user" class="fa-stack clickable" @click="addWord()">
                    <i class="fa-stack-2x fas fa-square c-purple"></i>
                    <i class="fa-stack-1x fas fa-plus"></i>
                </span>
                <span v-if="!item.in_user" class="fa-stack clickable" @click="trashWord()">
                    <i class="fa-stack-2x fas fa-square c-purple"></i>
                    <i class="fa-stack-1x fas fa-trash"></i>
                </span>
                <div v-if="locked && !item.in_user" class="fa-stack lock-warning" v-tippy :content="msg.lockAlert" @click="alert(msg.lockAlert)">
                    <i class="fa-stack-2x fas fa-square c-red"></i>
                    <i class="fa-stack-1x fas fa-exclamation-triangle"></i>
                </div>
                <span v-if="item.in_user" class="fa-stack clickable" @click="knownDelete()">
                    <i class="fa-stack-2x fas fa-square"></i>
                    <i class="fa-stack-1x fas fa-times"></i>
                </span>
                <span v-if="item.in_user" class="fa-stack clickable" @click="knownTrash()">
                    <i class="fa-stack-2x fas fa-square"></i>
                    <i class="fa-stack-1x fas fa-trash"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable consistent-return */
import { isKatakana, toHiragana, toKatakana } from 'wanakana'

const wanakana = {
    isKatakana, toHiragana, toKatakana
}
export default {
    name: 'Card',
    props: ['item', 'kanjiInfo', 'pronunciation', 'locked'],
    methods: {
        toggleAsActive(entry) {
            const found = this.item.entries.findIndex((i) => i.entry === entry)
            if (found || found === 0) this.activeIndex = found
        },
        resetSettings() {
            this.activeIndex = 0
        },
        addWord() {
            const { item, activeIndex } = this
            if (item.match === 'phonetic') return this.$emit('add-phonetic', { item, activeIndex })
            return this.$emit('add-exact', { item })
        },
        trashWord() {
            const { item } = this
            return this.$emit('add-trash', { item })
        },
        knownTrash() {
            const { item } = this
            const ids = [item.in_user]
            if (confirm('Are you sure you want to trash (mark as known) this word?')) {
                return this.$emit('known-trash', { item, ids })
            }
        },
        knownDelete() {
            const { item } = this
            const ids = [item.in_user]
            if (confirm('Are you sure you want to delete this word?')) {
                return this.$emit('known-delete', { item, ids })
            }
        },
        alert(msg) {
            window.alert(msg)
        },
        getPronunciationsForEntry(entry) {
            const { reading } = entry
            const found = this.relevantPronunciations.filter((i) => i.reading === wanakana.toKatakana(entry.reading))
            return found.length > 0 ? found : [{ pronunciation: reading }]
        }
    },
    watch: {
        item: {
            handler() {
                //  reset to defaults
                this.resetSettings()
            }
        }
    },
    computed: {
        relevantHeaderEntries() {
            const headers = []
            const headersMap = []
            this.item.entries.forEach((i) => {
                if (!headersMap.includes(i.entry)) {
                    headers.push(i)
                    headersMap.push(i.entry)
                }
            })
            return headers
        },
        relevantDefinitions() {
            //  words can have multiple entries revolving around a single character (different parts of speech, for instance)
            const theEntry = this.item.entries[this.activeIndex]
            const newList = this.item.entries.filter((i) => i.entry === theEntry.entry)
            return newList
        },
        relevantKanji() {
            const { kanjiInfo, item } = this
            const charMap = []
            const arr = []
            for (let i = 0; i < item.word.length; i += 1) {
                charMap.push(item.word[i])
            }
            return charMap.map((char) => {
                const found = kanjiInfo.find((i) => i.kanji === char)
                const kanji = found ? found.meaning.split(';').slice(0, 3).toString().replace(/,/g, ', ') : 'not a kanji'
                return { char, kanji }
            })
        },
        relevantPronunciations() {
            /* not working at all for phonetic matches */
            const { pronunciation, item } = this
            return pronunciation.filter((i) => i.word === item.entries[this.activeIndex].entry)
        },
        lockReason() {
            if (this.locked && this.locked === 'freq') return 'Frequency'
            if (this.locked && this.locked === 'wk') return 'WK'
            return ''
        },
    },
    data() {
        return {
            activeIndex: 0,
            wanakana,
            msg: {
                lockAlert: 'Warning due to not meeting wk level or min freq thresholds'
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.header-cell
    font-size: 1.5rem
.lock-warning
    padding-left: 7px
    .badge
        padding-left: 1em
        padding-right: 1em
.badge[role="pronunciation"]
    margin-right: .25em
    line-height: 1
    font-size: 60%
    padding-top: .75em
.bg-purple-2
    background-color: #b96aff
</style>

<style lang="sass">
body[mode="light"]
    .lock-card.book-card
        background-color: rgb(255, 247, 247)
</style>
