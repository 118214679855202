<template>
    <div>
        <div class="book-card pad-big marg-big">
            <h2 class="marg-big">Jumping</h2>
            If you're having trouble finding a word, you can JUMP to it. This can be done by searching for the word in the <span class="badge bg-purple clickable" @click="setNav('search')">Search</span> tab. Words that are jumped to are also pinned!
        </div>
        <div class="book-card pad-big marg-big">
            <h2 class="marg-big">Pinned Words</h2>
            Words that you've interacted with are automatically pinned. This lets you find all the words that you've found relevant in your current reading session easily. These words can be added in the <span class="badge bg-purple clickable" @click="setNav('pinned')">Pinned</span> menu as well, meaning you can add all the words you found interesting all at once without interrupting your reading session.
        </div>
        <div class="book-card pad-big marg-big">
            <h2 class="marg-big">Card Thresholds</h2>
            You can set a wk level and/or min freq in the <span class="badge bg-purple clickable" @click="setNav('options')">Options</span> menu. This will lock out words that don't meet the thresholds, making it easier to choose what words to add or not add.
        </div>
        <div class="book-card pad-big marg-big">
            <h2 class="marg-big">Parts of Speech</h2>
            The parts of speech displayed on Koohi use EDICT's classifications. You can find out more about it their documentation <a href="https://www.edrdg.org/jmdict/edict_doc_old.html" target="_blank">here</a> (scroll to the section "parts of speech").
        </div>
    </div>
</template>

<script>
export default {
    name: 'Help',
    methods: {
        setNav(nav) {
            this.$emit('nav-changed', nav)
        }
    }
}
</script>
