const leftHandler = (obj) => {
    if (obj.showingMain) {
        obj.jumpToTop()
        obj.pagination('left')
    }
}
const rightHandler = (obj) => {
    if (obj.showingMain) {
        obj.jumpToTop()
        obj.pagination('right')
    }
}

function Hotkeys() {
    return {
        a: {
            keyup: () => {
                leftHandler(this)
            }
        },
        left: {
            keyup: () => {
                leftHandler(this)
            }
        },
        d: {
            keyup: () => {
                rightHandler(this)
            }
        },
        right: {
            keyup: () => {
                rightHandler(this)
            }
        },
    }
}

export default Hotkeys
