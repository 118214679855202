<template>
    <div id="reader">
        <!-- top -->
        <Toasts
            v-on:hide-toasts="1==1"
            :toasts="toasts"
            v-on:close-all="toasts = []"
            v-on:disable-help="[opts.tutorial = false, toasts = []]"
        />
        <div v-hotkey.stop="Keymap">
            <Navigation
                class="marg-small"
                :active="flags.nav"
                :list="['selector', 'search', 'pinned', 'options', 'help']"
                v-on:nav-changed="changeNav($event)"
            />
            <Options
                v-if="books.length > 0 && flags.nav === 'options'" class="book-card container-fluid pad-big marg-big"
                :opts="opts"
            />
            <div v-if="books.length > 0 && flags.nav === 'selector'" class="book-card pad-big">
                <h2 class="marg-big">The Reader</h2>
                Unlike <router-link to="/list">Vocabulary Lists</router-link>, the Reader is designed to help people as they're actually reading.
                <br><b>Here you will be able to look up words in the book, jump to specific parts, and add words that are related to unrelated to the vocabulary list</b>.
                <br>Unlike in the normal vocabulary list generator, <b>the reader will also show words that you already know, so that you can easily reference their meanings while reading</b>.
                <br><i class="fas fa-exclamation-triangle c-red"></i> Reader lists will contain more words than standard vocab lists because there is no "minimum quality filter" in this mode.
            </div>
            <Selection
                v-if="books.length > 0 && flags.nav === 'selector'"
                class="col-12 book-card pad-big marg-big"
                :books="books"
                :msg="'Select a book'"
                v-on:book-changed="setBook($event)"
            />
            <Generate
                v-if="flags.nav === 'selector'"
                class=" col-12 col-md-8 mx-auto book-card align-center pad-big marg-big"
                :len="1"
                v-on:generate-list="() => { if(activeBook) generateListFlow({set: true}) }"
            >
                <span slot="msg" class="marg-small">
                    <span v-if="activeBook">Use <span  style="text-decoration: underline red 2px">{{activeBook.title.substr(0, 50) }}</span></span>
                    <span v-else>No book selected</span>
                </span>
            </Generate>
            <Search
                v-if="flags.nav === 'search'"
                class="book-card pad-big marg-big"
                v-on:req-add="syncSearchAndList({action: 'add', dat: $event})"
                v-on:req-delete="[startDelete({}, $event), syncSearchAndList({action: 'delete', ids: $event})]"
                v-on:req-trash="startTrash({}, $event)"
                v-on:refresh-list="''"
                v-on:jump-to-item="[jumpToItem($event), setPinned($event)]"
                :list="this.last.lists"
            >
                <div slot="custom-slot" class="clickable" @click.stop="openCustomWordModal()" v-tippy content="Additional actions">
                    <span
                        class="align-center"
                    >
                        <i class="fas fa-ellipsis-v"></i>
                    </span>
                </div>
            </Search>
            <CustomWord
                :words="dat"
                v-on:hide-modal="hideCustomWordModal()"
                v-on:force-refresh="()=>{}"
            />
            <Help
                v-if="flags.nav === 'help'"
                v-on:nav-changed="changeNav($event)"
            />
            <!-- app -->
            <Spinner
                v-if="flags.loading"
                :badge="'bg-success'"
                :message="'Loading'"
            />
            <Pagination
                id="pagination"
                v-else-if="showingMain"
                class="pad-big book-card"
                :flags="paginationFlags"
                :words="dat"
                v-on:next-page="pagination('right')"
                v-on:prev-page="pagination('left')"
                v-on:jump="jumpToIndex($event)"
            >
                <div slot="msg" class="align-center"><h2 v-html="titleToShow"></h2></div>
                <span slot="button-1" class="fa-stack" v-tippy content="Generate a share link"
                @click="() => { this.toasts.push(this.generateShareLink()) }"
                >
                    <i class="fa-stack-2x fas fa-square c-blue"></i>
                    <i :class="`fa-stack-1x fas fa-link`"></i>
                </span>
            </Pagination>
        </div>
        <!-- headers -->
        <div v-if="flags.nav === 'pinned' && cardsToShow.length === 0"
        class="book-card pad-big marg-big"
        >
            <h2>No cards pinned</h2>
        </div>
        <div v-if="paginationOpts.current === 0 && cardsToShow > 0"
        class="book-card pad-big marg-big container-fluid"
        >
            <div class="row">
                <div class="col-md-3"><h2>Word in Book</h2></div>
                <div class="col"><h2>Dictionary entries</h2></div>
                <div class="col-md-2"><h2>Actions</h2></div>
            </div>
        </div>
        <Card
            v-if="showingMain"
            class="marg-big col-12 col-md-11 mx-auto"
            v-for="(items, indx) in cardsToShow"
            :key="indx+'a'"
            :item="items"
            :kanji-info="kanji"
            :pronunciation="relevantPronunciations"
            :locked="itemIsLocked(items)"
            v-on:pin-card="setPinned($event)"
            v-on:add-phonetic="addPhonetic($event.item, $event.activeIndex)"
            v-on:add-exact="addExact($event.item)"
            v-on:add-trash="addTrash($event.item)"
            v-on:known-trash="startTrash($event.item, $event.ids)"
            v-on:known-delete="startDelete($event.item, $event.ids)"
        />
        <Pagination
                id="pagination"
                v-if="showingMain && (cardsToShow.length >= paginationOpts.step)"
                class="pad-big book-card"
                :flags="paginationFlags"
                :words="dat"
                v-on:next-page="[jumpToTop(), pagination('right')]"
                v-on:prev-page="[jumpToTop(), pagination('left')]"
                v-on:jump="jumpToIndex($event)"
            >
            <Top
                v-if="cardsToShow.length > 0"
                slot="button-1"
                class="d-inline"
                v-on:clicked="jumpToTop()"
            />
        </Pagination>
    </div>
</template>

<script>
import { toKatakana } from 'wanakana'
import Vue from 'vue'
import VueHotkey from 'v-hotkey'
import { Modal as Bmodal } from 'bootstrap'
import ApiPreloader from '@/assets/preloader'
import getSavedOptions from '@/assets/js/getSavedOptions'
import { SS } from '@/assets/constants'
import { UseAPI, HandleRequestFail, SendUserAlert } from '@/assets/common'
import Keymap from './hotkeys'
import Card from './Card.vue'
import Pagination from './Top/Pagination.vue'
import Navigation from './Top/Navigation.vue'
import Help from './Top/Help.vue'
import Top from './Top/ToTop.vue'
import Options from './Top/Options.vue'
//  from Vocabulary
import Selection from '../Vocabulary/Select/Select.vue'
import Generate from '../Vocabulary/Select/Generate.vue'
//  From Lessons
import CustomWord from '../Lessons/CustomWord.vue'
import Search from '../Lessons/Search.vue'
//  From Shared
import Toasts from '../Shared/Toasts.vue'
import Spinner from '../Spinner.vue'
import TOAST_CONFIG from '@/assets/js/toastsConfig/reader'

Vue.use(VueHotkey)

const wanakana = { toKatakana }
export default {
    name: 'Reader',
    components: {
        Card,
        Selection,
        Generate,
        Pagination,
        Navigation,
        CustomWord,
        Search,
        Help,
        Top,
        Options,
        Toasts,
        Spinner,
    },
    computed: {
        Keymap,
        paginationFlags() {
            return {
                pagination: this.paginationOpts.current,
                paginationLength: this.paginationOpts.step
            }
        },
        cardsToShow() {
            const { current, step } = this.paginationOpts
            let cards = this.dat
            if (this.flags.nav === 'pinned') {
                cards = this.dat.filter((i) => i.pinned)
                this.updatePinnedCards(cards)
                cards = this.pinnedCards
                if (this.opts.pinnedSortByFreq) {
                    const sortFunc = (a, b) => {
                        const aFreq = parseInt(a.freq, 10)
                        const bFreq = parseInt(b.freq, 10)
                        if (aFreq < bFreq) return 1
                        if (aFreq > bFreq) return -1
                        return 0
                    }
                    cards = cards.sort(sortFunc)
                }
            }
            return cards.slice(current, current + step)
        },
        relevantPronunciations() {
            let wordMap = []
            this.cardsToShow.forEach((aCard) => {
                const temp = aCard.entries.map(((anEntry) => ({ word: anEntry.entry, reading: anEntry.reading })))
                wordMap = [...wordMap, ...temp]
            })
            return this.pronunciation.filter((pronun) => wordMap.find((wordMapItem) => wordMapItem.word === pronun.word && pronun.reading === wanakana.toKatakana(wordMapItem.reading)))
        },
        titleToShow() {
            if (this.flags.nav === 'pinned') return `<i class="fas fa-thumbtack"></i>`
            return this.last.activeListTitle ? this.last.activeListTitle : 'From Memory'
        },
        shareLink() {
            return `${window.location.host}/#/reader?list=${this.last.lists}&item=${this.paginationOpts.current}`
        },
        showingMain() {
            //  hide <pagination> and <card> if the conditions aren't met
            return this.dat.length > 0 && this.flags.nav !== 'help'
        },
    },
    data() {
        return {
            toasts: [],
            possibleToasts: null, // set at created()
            opts: {
                lists: "",
                freq: 0,
                wk: 0,
                pinnedSortByFreq: false,
            },
            paginationOpts: {
                current: 0,
                step: 20,
                mainProg: 0,
                pinProg: 0,
            },
            last: { // snapshot of last search
                activeListTitle: '',
                lists: '',
                activeBook: null,
                farthest: 0,
            },
            modals: {
                custom: null
            },
            flags: {
                optionsInitiated: false, // has done initial options setup in created()
                nav: 'selector',
                loading: false,
            },
            dat: [],
            books: [],
            pinnedCards: [],
            activeBook: null,
            kanji: [],
            pronunciation: [],
        }
    },
    methods: {
        //  API
        async addTrash(item) {
            const finalItem = {
                card: item.word,
                readings: ['none']
            }
            const finalData = {
                items: [finalItem],
                source: this.last.lists
            }
            UseAPI("/create/add-trash", { method: "PUT", body: JSON.stringify(finalData) })
            .then((dat) => {
                this.handleAddResponse(item, dat)
            })
            .catch((dat) => {
                if (!SS.get(SS.PUBLIC_API)) {
                    SendUserAlert('There was an error modifying the item(s)', 'user-danger')
                }
                HandleRequestFail(dat)
            })
        },
        async addExact(item) {
            const finalItem = {
                card: item.word,
                readings: []
            }
            item.entries.forEach((entry) => {
                finalItem.readings.push(entry.reading)
            })
            finalItem.readings = finalItem.readings.toString()
            const finalData = {
                items: [finalItem],
                source: this.last.lists
            }
            UseAPI("/create/add-exact", { method: "PUT", body: JSON.stringify(finalData) })
            .then((dat) => {
                this.handleAddResponse(item, dat)
                // also clear preload data
                ApiPreloader.clearApiKey('/lessons')
            })
            .catch((dat) => {
                if (!SS.get(SS.PUBLIC_API)) {
                    SendUserAlert('There was an error adding the item(s)', 'user-danger')
                }
                HandleRequestFail(dat)
            })
        },
        async addPhonetic(item, activeIndex) {
            const items = []
            const theCard = item.entries[activeIndex]
            items.push({
                card: theCard.entry,
                readings: theCard.reading,
                q_state: 0,
            })
            console.log('items', items, 'item', item, 'theCard', theCard)
            // trash the phonetic guess entry (that isnt a real word)
            const finalData = {
                items,
                source: this.last.lists,
                list_word: {
                    card: item.word,
                    readings: 'none'
                }
            }
            UseAPI('/create/add-phonetic', { method: "PUT", body: JSON.stringify(finalData) })
            .then((dat) => {
                this.handleAddResponse(item, dat)
                // also clear preload data
                ApiPreloader.clearApiKey('/lessons')
            })
            .catch((dat) => {
                if (!SS.get(SS.PUBLIC_API)) {
                    SendUserAlert('There was an error adding the item(s)', 'user-danger')
                }
                HandleRequestFail(dat)
            })
        },
        handleAddResponse(item, dat) {
            console.log('received item', item)
            this.setPinned(item.id)
            /* item.in_user = true */
            item.in_user = dat.modules.created[0].id
        },
        startDelete(item, ids) {
            return UseAPI('/delete/', {
                method: "DELETE", body: JSON.stringify({ items: ids })
            })
            .then((dat) => {
                item.in_user = null
            })
            .catch((dat) => {
                SendUserAlert('there was an error deleting the words\ncheck console for error logs', 'alert-danger')
                HandleRequestFail(dat)
                console.log('failed to delete; error log')
                console.log(JSON.stringify(dat))
            })
        },
        startTrash(item, ids) {
            const vm = this
            UseAPI('/update/trash', {
                method: "POST",
                body: JSON.stringify({
                    items: ids
                })
            })
            .then((dat) => {
            })
            .catch((dat) => {
                SendUserAlert('there was an error deleting the words\ncheck console for error logs', 'alert-danger')
                HandleRequestFail(dat)
                console.log('failed to delete; error log')
                console.log(JSON.stringify(dat))
            })

            return ids
        },
        syncSearchAndList(opts) {
            //  add/delete/trash from the <search> will desync the list. Attempt to fix this
            const { action, ids = null, dat = null } = opts
            const theId = Array.isArray(ids) ? ids[0] : ids
            if (action === 'delete') {
                //  change in_user to null, making it an "unknown" word as far as interface is concerned
                const found = this.dat.find((i) => i.in_user === theId)
                found.in_user = null
            } else if (action === 'add') {
                //  expecting a dat item, parse it
                const addedId = dat?.modules?.created[0]?.id || null
                const addedWord = dat?.modules?.created[0]?.item?.card || null
                if (addedId) {
                    const found = this.dat.find((i) => i.word === addedWord)
                    if (found) found.in_user = addedId
                }
            }
            const debug = {
                action,
                actionType: typeof action,
                theId,
                theIdType: typeof theId,
                dat,
                datType: typeof dat
            }
            console.table(debug)
        },
        //  #controls
        pagination(val) {
            //  handle pagination
            const upstep = () => { this.paginationOpts.current += this.paginationOpts.step }
            const toStart = () => { this.paginationOpts.current = 0 }
            const downstep = () => { this.paginationOpts.current -= this.paginationOpts.step }
            const toEnd = () => {
                const len = this.flags.nav === 'pinned' ? this.pinnedCards.length : this.dat.length
                this.paginationOpts.current = len - (this.dat.length % this.paginationOpts.step)
            }

            if (val === 'right') {
                const len = this.flags.nav === 'pinned' ? this.pinnedCards.length : this.dat.length
                len > (this.paginationOpts.current + this.paginationOpts.step)
                    ? upstep()
                    : toStart()
            } else if (val === 'left') {
                (this.paginationOpts.current - this.paginationOpts.step) >= 0
                    ? downstep()
                    : toEnd()
                if (this.paginationOpts.current === this.dat.length) downstep()
            }
        },
        jumpToIndex(position) {
            if (position >= 0 && position < this.dat.length) this.paginationOpts.current = position
        },
        jumpToItem(position) {
            const found = this.dat.findIndex((i) => i.id === position)
            this.paginationOpts.current = found
        },
        jumpToTop() {
            const paginationNode = document.getElementById('pagination')
            if (paginationNode) paginationNode.scrollIntoView();
        },
        setBook(bookId) {
            //  set active book
            const activeBook = this.books.find((aBook) => parseInt(aBook.id, 10) === parseInt(bookId, 10))
            this.activeBook = activeBook
            this.opts.lists = activeBook.vocab_list
        },
        setPinned(id) {
            const found = this.dat.find((i) => i.id === id)
            if (found) found.pinned = true
        },
        updatePinnedCards(cards) {
            this.pinnedCards = [...cards]
        },
        changeNav(to) {
            if (this.flags.nav === 'pinned') this.paginationOpts.pinProg = this.paginationOpts.current
            if (this.flags.nav !== 'pinned') this.paginationOpts.mainProg = this.paginationOpts.current
            this.flags.nav = to
            if (to === 'pinned') {
                //  set current position
                this.paginationOpts.current = this.paginationOpts.pinProg
            } else {
                this.paginationOpts.current = this.paginationOpts.mainProg
            }
        },
        itemIsLocked(item) {
            if (parseInt(item.freq, 10) < this.opts.freq) return 'freq'
            if (item.in_wk && parseInt(item.in_wk, 10) < this.opts.wk) return 'wk'
            return false
        },
        //  #list retrieval
        generateListFlow(opts) {
            //  get a vocab list, attempt to set cookie, return vocab list
            //  try and catch will still cause the promise chain to break so this requires .catch blocks
            const { set } = opts
            this.flags.loading = true
            return this.getVocabList()
            .then((result) => {
                this.flags.loading = false
                return result
            })
            .then((result) => {
                /* console.log('fetched', result) */
                try {
                    this.setReaderCookie(result)
                } catch (e) {
                    console.warn('Unable to set reader cookie', e)
                }
                return result
            })
            .then((result) => {
                try {
                    this.setKanjiCookie(result.modules.kanjiInfo)
                } catch (e) {
                    console.warn('Unable to set reader kanji cookie', e)
                }
                return result
            })
            .then((result) => {
                //  set lists
                if (set) this.dat = result.items
                if (set) this.kanji = result?.modules.kanjiInfo.items || []
                if (set) this.pronunciation = result?.modules.pronunciation.items || []
                //  create a snapshot of the search
                this.makeSnapshot()
                this.flags.nav = "search"
                //  set page if value exists
                const hasUrlParam = this.getUrlParam()
                if (hasUrlParam.item) {
                    const paramAsInt = parseInt(hasUrlParam.item, 10)
                    console.log('looking for page url param', parseInt(hasUrlParam.item, 10))
                    if (isNaN(paramAsInt) === false) {
                        this.dat.length > paramAsInt
                        ? this.paginationOpts.current = paramAsInt
                        : this.paginationOpts.current = (this.dat.length - 2)
                    }
                }
                return result.items
            })
            .then((result) => {
                //  handle public
                if (SS.get(SS.PUBLIC_API)) {
                    this.toasts.push(this.possibleToasts.thisIsAPreview)
                }
            })
        },
        getVocabList() {
            //  grab a vocabulary list using settings from this.opts
            //  freq must be locked to 0
            const { lists } = this.opts
            const method = SS.get(SS.PUBLIC_API) ? "GET" : "POST"
            return UseAPI("/get/list-reader", {
                method, queryParameters: `list=${lists}&freq=0&wk=60`
            })
        },
        setListFromUrlParamFlow() {
            //  called after getting book list to see if you can just create everything automatically
            const hasUrlParam = this.getUrlParam()
            if (hasUrlParam.list) {
                const found = this.findBook(hasUrlParam.list)
                if (found) {
                    this.activeBook = found
                    this.opts.lists = found.vocab_list
                    this.generateListFlow({ set: true })
                    this.toasts.push(this.possibleToasts.autogenerate)
                }
            }
        },
        generateShareLink() {
            const id = `share_link${Math.floor(Math.random() * 100)}`
            const shareLink = {
                header: 'Share Link',
                body: `<input id="${id}" value="${this.shareLink}" class="w-100" />`,
                buttons: [{
                    color: 'btn-info',
                    text: 'Copy Link',
                    action: () => {
                        const copyText = document.getElementById(id);
                        copyText.select();
                        copyText.setSelectionRange(0, 99999); /* For mobile devices */
                        document.execCommand("copy");
                    }
                }],
                headerStyle: 'bg-primary',
                bodyStyle: 'bg-light bg-gradient text-dark'
            }
            return shareLink
        },
        //  #cookie handling
        retrieveReaderCookie() {
            //  check if words cookie exists and return it
            const cookie = window.sessionStorage.getItem('reader_storage')
            if (cookie) return cookie
            return null
        },
        setReaderCookie(result) {
            //  set words cookie
            const cookie = {
                list: this.opts.lists,
                dat: result
            }
            window.sessionStorage.setItem('reader_storage', JSON.stringify(cookie))
        },
        retrieveKanjiCookie() {
            //  check if words cookie exists and return it
            const cookie = window.sessionStorage.getItem('reader_k_storage')
            if (cookie) return cookie
            return null
        },
        setKanjiCookie(result) {
            //  set words cookie
            const cookie = {
                list: this.opts.lists || [],
                dat: result
            }
            window.sessionStorage.setItem('reader_k_storage', JSON.stringify(cookie))
        },
        setFromMemory(parsedCookie, kanjiCookie) {
            //  set dat (word) and kanji lists
            this.dat = parsedCookie.dat.items
            this.kanji = kanjiCookie.dat.items
            //  try to set the title by finding the list value among the books
            const found = this.findBook(parsedCookie.list)
            console.log('found is', found)
            if (found) this.last.activeListTitle = found.title
            //  set the active_list (for search) by reading the parsedCookie
            this.last.lists = parsedCookie.list
            //  set active nav to search
            this.flags.nav = 'search'
            //  set pins
            this.dat.forEach((i) => {
                i.pinned = false
            })
        },
        makeSnapshot() {
            this.last.activeListTitle = this.activeBook.title
            this.last.lists = this.opts.lists
            this.last.activeBook = this.activeBook
            this.dat.forEach((i) => {
                i.pinned = false
            })
        },
        //  #handle books
        getBooks() {
            return new Promise((resolve, reject) => {
                const method = SS.get(SS.PUBLIC_API) ? "GET" : "POST"
                UseAPI('/get/booksWithFlags', { method })
                .then((dat) => resolve(dat))
                .catch((dat) => {
                    HandleRequestFail(dat)
                    reject()
                })
            })
        },
        addBooks() {
            //  add received book api request to component list of books
            this.getBooks()
            .then((result) => {
                result.items.forEach((item) => this.books.push(item))
            })
            .catch((result) => {
                console.warn('Issue fetching books', result)
            })
            .then(() => {
                //  try to set from param
                this.setListFromUrlParamFlow()
            })
        },
        findBook(listName) {
            console.log('running findBook', listName, this.books)
            return this.books.find((i) => listName === i.vocab_list)
        },
        getUrlParam() {
            const queryParam = this.$route.query
            if (queryParam) return this.$route.query
            return false
        },
        //  #modals
        openCustomWordModal() {
            this.modals.custom.show()
        },
        hideCustomWordModal() {
            this.modals.custom.hide()
        },
        //  #options
        saveSettings() {
            //  send to server
            const body = JSON.stringify({
                context: 'reader',
                val: JSON.stringify(this.opts)
            })
            if (this.flags.optionsInitiated) {
                UseAPI('/create/user-settings', {
                    method: "PUT",
                    body
                })
                .then((result) => {
                    SendUserAlert('Settings saved to server', 'alert-success')
                    //  remove cookie and refresh, so that it doesn't hold up future page loads
                    SS.remove(SS.USER_SETTINGS_COOKIE)
                    getSavedOptions()
                })
                .catch((result) => {
                    SendUserAlert('Error saving user settings to server', 'alert-danger')
                    console.log('Error saving user settings to server', result)
                })
            }
        },
    },
    async created() {
        console.log('%cCreated component reader', window.ConsoleStyles.createdComponent, this)
        await this.addBooks()

        this.possibleToasts = TOAST_CONFIG

        const CONTEXT = 'reader'
        return getSavedOptions(CONTEXT)
        .then((resultingOpts) => {
            Object.keys(resultingOpts).forEach((i) => {
                this.opts[i] = resultingOpts[i]
            })
            console.log('%cgetUserSettings', window.ConsoleStyles.routine, resultingOpts, this.opts)
        })
        .catch((result) => {
            if (!SS.get(SS.PUBLIC_API)) {
                console.log('getUserSettings', result)
                SendUserAlert(`Failed to fetch user settings: ${result}`, 'alert-warning')
            }
        })
        .then(() => {
            this.flags.optionsInitiated = true
        })
    },
    watch: {
        opts: {
            handler(arr) {
                if (!SS.get(SS.PUBLIC_API)) this.saveSettings()
            },
            deep: true
        }
    },
    mounted() {
        const setupModals = () => {
            const myModal = new Bmodal(document.getElementById('custom-word-modal'), {})
            this.modals.custom = myModal
        }
        setupModals()
    },
}
</script>

<style lang="sass" scoped>
#reader
    max-width: 900px
    margin: auto
</style>
