<template>
    <div>
        <span class="fa-stack clickable" @click="toTop()">
            <i class="fa-stack-2x fas fa-square c-blue"></i>
            <i :class="`fa-stack-1x fas fa-home`"></i>
        </span>
    </div>
</template>

<script>
export default {
    name: 'Top',
    methods: {
        toTop() {
            this.$emit('clicked')
        }
    }
}
</script>
