export default {
    autogenerate: {
        header: 'Automatically Generating List',
        body: 'The url has a list included as an option, so auto-generating content.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    thisIsAPreview: {
        header: 'This is a Preview',
        body: 'This is a small 1000 item preview of the list. Since you\'re not logged in it will be impossible to add/trash items.',
        headerStyle: 'bg-danger',
        bodyStyle: 'bg-light bg-gradient text-dark'
    }
}
