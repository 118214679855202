<template>
    <div class="book-card">
        <div v-if="wordLen > flags.paginationLength || flags.pagination != 0" class="pad-big tutorial w-90">
            <slot name="msg"></slot>
            <div class="pad-small d-flex align-center">
                <div style="flex:1 1 1px"  class="clickable anim-shimmer">
                    <span class="fa-stack" @click="$emit('prev-page')">
                        <i class="fa-stack-2x fas fa-square c-purple"></i>
                        <i :class="`fa-stack-1x ${!atFirstPage ? 'fas fa-angle-double-left' : 'fas fa-step-backward'}`"></i>
                    </span>
                </div>
                <div v-tippy :content="`${wordLen} words total`">
                    Showing <strong class="c-purple">{{flags.pagination+1}}</strong> -
                    <strong class="c-purple">{{ paginationCalc }}</strong>
                </div>
                <div style="flex:1 1 1px"  class="clickable anim-shimmer">
                    <span class="fa-stack" @click="$emit('next-page')">
                        <i class="fa-stack-2x fas fa-square c-purple"></i>
                        <i :class="`fa-stack-1x ${!atLastPage ? 'fas fa-angle-double-right' : 'fas fa-step-forward'}`"></i>
                    </span>
                    <span class="fa-stack" @click="jump()" v-tippy content="Jump to item #">
                        <i class="fa-stack-2x fas fa-square c-blue"></i>
                        <i :class="`fa-stack-1x fas fa-directions`"></i>
                    </span>
                    <slot name="button-1"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: [
        'flags',
        'words',
    ],
    methods: {
        jump() {
            // -1 because pagination will display current position + 1
            const jumpPrompt = prompt('Select item to jump to')
            // eslint-disable-next-line eqeqeq
            if ((jumpPrompt || jumpPrompt == 0) && !isNaN(parseInt(jumpPrompt, 10))) {
                this.$emit('jump', parseInt(jumpPrompt - 1, 10))
            }
        }
    },
    computed: {
        paginationCalc() {
            const { flags, wordLen } = this
            return this.flags.pagination + this.flags.paginationLength > this.wordLen
                            ? this.wordLen
                            : this.flags.pagination + this.flags.paginationLength
        },
        atFirstPage() {
            const { flags, wordLen } = this
            if (flags.pagination === 0) return true
            return false
        },
        atLastPage() {
            const { flags, wordLen } = this
            if (flags.pagination + flags.paginationLength > wordLen) return true
            return false
        },
        wordLen() {
            return this.words.length || 0
        }
    }
}
</script>
